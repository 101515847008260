import React from 'react';
import { graphql } from 'gatsby';

import Layout from '~/components/layout';
import PageHeading from '~/components/styled/page-heading';
import MemeList from '~/components/meme-list';
import SEO from '~/components/seo';

const SearchPage = ({
  data: {
    allStrapiMeme: { edges },
  },
}) => {
  const flatMemes = edges.map(({ node }) => node);

  const seo = { title: 'Memes' };

  return (
    <Layout>
      <SEO seo={seo} />
      <PageHeading>Memes</PageHeading>
      <MemeList memes={flatMemes} />
    </Layout>
  );
};

export const searchPageQuery = graphql`
  query MemeSearchQuery {
    allStrapiMeme {
      edges {
        node {
          specifications {
            key
            value
          }
          title
          slug
          id
          description
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                aspectRatio: 1.3
              )
            }
          }
        }
      }
    }
  }
`;

export default SearchPage;
